import React, {useState, useEffect, useReducer} from "react"
import {Form, Button, Row, Col} from 'react-bootstrap'
import {FaSearch} from 'react-icons/fa'

import {Error} from './Error'
import {searchUser} from '../API/githubRestAPI'
import {fetchMatchedIssues} from '../API/githubRestAPI'

function reducer(state, {type, payload}) {
    switch (type) {
        case "setData":
            return {
                userName: payload.login,
                repos: payload.public_repos,
                avatar: payload.avatar_url
            }
        case "updateChange":
            return {
                userInput: payload.value
            }
        default:
            return state;
    }
}

const SearchTrainee = (props) => {

    const [isError, setError] = useState({error: false, msg: ""});
    const [input, setInput] = useState("")
    const [date, setDate] = useState("")

    const initialState = {
        userName: '',
        repos: '',
        avatar: '',
        userInput: '',
        dateMonth: '',
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleSearch = async (e) => {
        e.preventDefault();


        handleSearchUser(state.userInput)

        // searchUser(state.userInput)
        //     .then(res => {
        //         dispatch({type: "setData", payload: res});


        //         props.handler({trainee: {name: res.login, avatar: res.avatar_url}});

        //         setError({error: false, msg: ""});
        //     })
        //     .catch((err) => {
        //         setError(err);
        //     });
    }

    const handleSearchUser = (user) => {
        searchUser(user)
            .then(res => {
                dispatch({type: "setData", payload: res});


                props.handler({trainee: {name: res.login, avatar: res.avatar_url}});

                setError({error: false, msg: ""});
            })
            .catch((err) => {
                setError(err);
            });
    }

    const handleInfo = () => {
        props.handler({dateWeek: date})
    }


    const userFound = () => {
        if (isError.error) {
            return (
                <Error msg={isError.msg}/>
            )
        }


        if (state.userName) {
            return (
                <div className="git-card">
                    <Row>
                        <Col md={3}>
                            <img src={state.avatar} className="git-card-img" alt="user-avatar"/>
                        </Col>
                        <Col md={9} className="my-auto git-card-text">
                            <h5>{state.userName}</h5>
                            <p className="text-muted">{state.repos} Repos</p>
                        </Col>
                    </Row>
                </div>
            )
        }
    };

    const handleInput = (e) => {
        setInput(e.target.value)
        setError(false)
    }
    


    const handleBlur = (e) => {
        setInput(e.target.value)
        
        dispatch({ type: 'updateChange', payload: {value: input}})

        handleSearchUser(input)
    }

    const renderContent = () => {
        return (
            <>
                <h2 className="borderBottom">2. Zoek de trainee</h2>
                <Form onSubmit={handleSearch}>
                    <Form.Group className="mb-3 flex" controlId="username">
                        <Row>
                            <Col><Form.Control
                                type="text"
                                placeholder="Github username"
                                name="username"
                                value={input}
                                onChange={(e) => handleInput(e)}
                                onBlur={(e) => handleBlur(e)}
                            /></Col>
                            <Col md="auto" className="text-right">
                                <Button className="icon-button"
                                        variant="dark"
                                        type='submit'
                                        onClick={(e) => dispatch({
                                            type: 'updateChange',
                                            payload: {value: input},
                                        })}
                                >
                                    <FaSearch/>
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col md="6"><Form.Group>
                            <Form.Control
                                className="datepicker"
                                type="date"
                                name='date_of_birth'
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Form.Group></Col>
                        <Col><Button className="btn-block" onClick={handleInfo}>Volgende</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }


    return (
        <div className="">
            {renderContent()}
            {userFound()}
        </div>
    )

}

export default SearchTrainee;