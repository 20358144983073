import React from 'react'
import './resources/styles/main.css';

import Main from './views/Main'

function App() {

    return (
        <div className="App">
            <Main/>
        </div>
    )

}

export default App;
