import {Octokit} from "octokit"
import axios from "axios"
import moment from "moment"

import config from "../config/config"
import {parseError, debugOutput} from './Generic'

let errorObject = {};
let octokit = {};

const getUser = () => {
    return ({
        username: localStorage.getItem("username"),
        userToken: localStorage.getItem("userToken")
    })
}

const setUser = (username, userToken) => {
    localStorage.setItem("username", username);
    localStorage.setItem("userToken", userToken);
}

const createOctokit = () => {
    let localStoredName = localStorage.getItem("username");
    let localStoredToken = localStorage.getItem("userToken");
    if (localStoredName && localStoredToken) {
        octokit = new Octokit({
            auth: localStoredToken,
            baseUrl: config.baseUrl,
        });
        return octokit;
    } else {
        return false;
    }
};


const createIssue = (params, octokit) => new Promise((resolve, reject) => {
    octokit.request("POST /repos/{owner}/{repo}/issues",
        params
    ).then((result) => {
        resolve({status: true, result: result});
    }).catch((err) => {
        debugOutput({ERRISSUES: err})
        errorObject = parseError(err);
        reject({status: false, err: errorObject});
    })
})

const createIssues = async (params, issues, octokit) => {

    let result = true;

    try {
        for (const matchedIssue of issues) {

            let issues = {
                id: matchedIssue.id,
                owner: params.account.username,
                repo: matchedIssue.repo,
                title: matchedIssue.title,
                body: matchedIssue.body,
                labels: [matchedIssue.labels]
            }
            result = await createIssue(issues, octokit)
        }
        debugOutput({createIssues: config.status.oke})
        return result.status
    } catch (err) {
        debugOutput({createIssues: config.status.err})
        return false
    }
}

const createRepoFromTemplate = (params, octokit) =>
    new Promise((resolve, reject) => {
        octokit
            .request("POST /repos/Educom-Opleidingen/{template_repo}/generate", {
                template_repo: params.oldRepository,
                name: params.newRepository,
            })
            .then((result) => {
                debugOutput({createRepoFromTemplate: config.status.oke})
                resolve(result);
            })
            .catch((err) => {
                err.action = "Kopiëren van template";
                errorObject = parseError(err);
                reject(errorObject);
            });
    });

const createProject = (params, octokit) =>
    new Promise((resolve, reject) => {
        octokit
            .request("POST /repos/{owner}/{repo}/projects", {
                owner: params.account.username,
                repo: params.newRepository,
                name: params.newRepository,
            })
            .then((result) => {
                debugOutput({createProject: config.status.oke})
                resolve(result);
            })
            .catch((err) => {
                err.action = "Creëren van project";
                errorObject = parseError(err);
                reject(errorObject);
            });
    });


const fetchMatchedIssues = (params) => new Promise((resolve, reject) => {

        let issuesList = []

        fetchTemplates()
            .then((result) => {
                const matchedIssues = result.filter((obj) => {
                    return obj.template === params.oldRepository;
                });

                matchedIssues.forEach((matchedElement) => {

                    matchedElement.Issues.forEach((isx, index) => {

                        let issueWeek = parseInt(isx.value.Week) - 1;
                        let selectedWeek = params.dateWeek
                        let currentWeek = moment(selectedWeek).isoWeek();
                        let calcWeek = "week-" + (currentWeek + issueWeek);

                        let issue = {
                            id: index,
                            repo: params.newRepository,
                            title: isx.value.issueTitle,
                            body: isx.value.issueDescription,
                            labels: calcWeek
                        }
                        issuesList.push(issue)
                    })

                })
                /// Issues worden correct opgehaald!
                debugOutput({fetchMatchedIssues: config.status.oke, issuesList: issuesList})
                resolve(issuesList)
            })
            .catch((err) => {
                err.action = "Ophalen van issues";
                errorObject = parseError(err);
                reject(errorObject);
            });
    })
;

const fetchTemplates = () =>
    new Promise((resolve, reject) => {
        const url = `${config.cockpitURL}/?token=${config.cockpitToken}`;
        axios
            .get(url)
            .then((result) => {
                resolve(result.data.entries.sort((a, b) => b.subject < a.subject));
            })
            .catch((err) => {
                errorObject = parseError(err);
                reject(errorObject);
            });
    });

const transferRepo = (params, octokit) =>
    new Promise((resolve, reject) => {
        octokit
            .request("POST /repos/{owner}/{repo}/transfer", {
                owner: params.account.username,
                repo: params.newRepository,
                new_owner: params.trainee.name,
            })
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                err.action = "Overzetten van Repo";
                errorObject = parseError(err);
                reject(errorObject);
            });
    });

const searchUser = (params) =>
    new Promise((resolve, reject) => {
        axios
            .get(`${config.baseUrl}/users/${params}`, {
                validateStatus: function (status) {
                    if (status === 404) {
                        reject({error: true, msg: "niet gevonden"});
                    }
                    return status === 200;
                },
            })
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                errorObject = parseError(err);
                reject(errorObject);
            });
    });

const copyAll = async (params, octokit) => {

    try {
        let createRepo = await createRepoFromTemplate(params, octokit);
        let createPrj = true; //await createProject(params, octokit);
        let matchIssues = await fetchMatchedIssues(params);
        let createIs = await createIssues(params, matchIssues, octokit);
        let transferAll = await transferRepo(params, octokit);

        return {status: createRepo || matchIssues || createIs || createPrj || transferAll }

    } catch (err) {
        debugOutput({WEHAVEANERROR: err})
        return {status: false, err: err};
    }
};

export {
    getUser, setUser,
    createOctokit,
    fetchTemplates,
    fetchMatchedIssues,
    searchUser,
    copyAll,
};
