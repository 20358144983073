import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'

import config from '../config/config'
import {debugOutput} from '../API/Generic'

import Header from '../components/Header'
import Spinner from '../components/Spinner'
import {getUser, fetchTemplates} from '.././API/githubRestAPI'
import ChooseTemplate from '../components/ChooseTemplate'
import SearchTrainee from '../components/SearchTrainee'
import TransferRepo from '../components/TransferRepo'
import PopupGegevens from '../components/PopupGegevens'
import Success from '../components/Success'

const workflowParams = {
    oldRepository: '',
    newRepository: '',
    trainee: {name: '', avatar: config.defaultAvatar},
    dateWeek: '',
    issues: [],
    account: {username: '', userToken: ''}
}

const Main = () => {

    const [isLoaded, setLoaded] = useState(false)
    const [isError, setError] = useState({error: false, msg: ''});
    const [transferDone, setTransferDone] = useState(false)

    const [showUser, setShowUser] = useState(false)
    const [workflow, setWorkflow] = useState(workflowParams)
    const [templates, setTemplates] = useState([])
    const [active, setActive] = useState(false)

    useEffect(() => {
        const {username, userToken} = getUser()
        updateWorkflow({account: {username, userToken}})
    }, [])

    const updateWorkflow = (params) => {
        let p = {
            oldRepository: params.oldRepository ? params.oldRepository : workflow.oldRepository,
            newRepository: params.newRepository ? params.newRepository : workflow.newRepository,
            githubURL: params.githubURL ? params.githubURL : workflow.githubURL,
            account: params.account ? params.account : workflow.account,
            trainee: params.trainee ? params.trainee : workflow.trainee,
            dateWeek: params.dateWeek ? params.dateWeek : workflow.dateWeek,
            issues: params.issues ? params.issues : workflow.issues,
        }
        debugOutput({WORKFLOW: p})
        setWorkflow(p)
    }

    useEffect(() => {
        const u = getUser()
        if (u.username && u.userToken) {
            setShowUser(false)
        } else {
            setShowUser(true)
        }

        fetchTemplates().then((result) => {
            setTemplates(result);
            setLoaded(true);
        })
            .catch((err) => {
                setError({error: true, msg: err.msg});
            });

    }, [])

    const showSearch = () => {
        if (active){
            return(     
                <Col md={4} className={'px-5 search-trainee collapsee' + (active? ' active': ' ')}>
                    <SearchTrainee handler={updateWorkflow} workflow={workflow}/>
                </Col>
            )
        }
    }

    const showTransfer = () => {
        if (workflow.newRepository && workflow.trainee && workflow.dateWeek) {
            return (
                <Col md={4} className="px-5">
                    <TransferRepo data={workflow} setTransferDone={setTransferDone} handler={updateWorkflow} workflow={workflow}/>
                </Col>
            )
        }
    }

    const renderContent = () => {
        if (!isLoaded && !isError.error && !transferDone) {
            return <Spinner/>
        }

        if (isLoaded && !transferDone) {
            return (
                <div>
                    <Header setShowUser={setShowUser} showUser={showUser} handler={updateWorkflow}/>
                    {/* {popupContent()} */}
                    <div id="popup" className={'collapsee' + (showUser ? ' active' : ' ')}>
                        <PopupGegevens setShowUser={setShowUser} handler={updateWorkflow} showUser={showUser}/>
                    </div>

                    <div id="main">
                        <Row className="flex-container">
                            <Col md={4} className="px-5">
                                <ChooseTemplate templates={templates} handler={updateWorkflow} active={setActive}/>
                            </Col>

                            {showSearch()}
                            

                            {showTransfer()}
                        </Row>
                    </div>
                </div>
            )
        }

        if (transferDone && isLoaded) {
            return (
                <Success data={workflow} handler={updateWorkflow} setShowUser={setShowUser} showUser={showUser}/>
            )
        }
    }


    return (
        <div className="">
            {renderContent()}
        </div>
    )
}

export default Main