const Error = (props) => {

    const {msg} = props
    return (
        <div className="error">
            <h4>{msg}</h4>
        </div>
    )
}
const errorFound = (isError) => {
    return (isError.error ? <Error msg={isError.msg}/> : null)
}

export {Error, errorFound}