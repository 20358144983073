import config from '../config/config'


const debugOutput = (obj) => {
    if (config.debug) {
        console.log(obj)
    }
}

const parseError = (err) => {
    const errorObject = {
        code: err.status,
        msg: err.msg,
        action: err.action,
        error: true
    }


    switch (err.status) {
        case 200:
            return "okay";
        case 403:
            errorObject.msg = err.action + " is mislukt, je bent niet geauthorizeerd om deze actie uit te voeren. (update de accountgegevens)";
            break;
        case 404:
            errorObject.msg = err.action + " is mislukt, omdat deze niet gevonden wordt.";
            break;
        case 422:
            errorObject.msg = err.action + " is mislukt, naam van de repository bestaat al op jouw account.";
            break;
        case 500:
            errorObject.msg = err.action + " is mislukt, door een internal server error";
            break;
        default:
            return ("dit was kennelijk een andere status");
    }
    return errorObject;
}


export {parseError, debugOutput}