import React, {useState, useEffect} from "react"
import {Button} from 'react-bootstrap'
import arrowDown from '../assets/img/arrow-down.png';
import {createOctokit, copyAll, fetchMatchedIssues} from "../API/githubRestAPI";
import Spinner from './Spinner';
import {Error} from "./Error";

const TransferRepo = (props) => {
    const [isLoaded, setLoaded] = useState(true)
    const [isError, setError] = useState({error: false, msg: ""})
    const [workflowData, setWorkflowData] = useState(props.data)

    const handleTransfer = async () => {
        setLoaded(false)
        let octo = createOctokit()
        let res = await copyAll(workflowData, octo)
        if (res.status) {
            setLoaded(true)
            props.setTransferDone(true)
        } else {
            setError({error: true, msg: res.err.msg});
        }
    }

    useEffect(() => {

        fetchMatchedIssues(props.workflow).then(result => {
            props.handler({issues: result})
        });

    }, [])

    const renderContent = () => {
        if (!isLoaded && !isError.error) {
            return <Spinner/>;
        }

        if (isError.error) {
            return (<Error msg={isError.msg}/>)
        }

        if (isLoaded) {
            return (
                <>
                    <h2 className="borderBottom">3. Transfer de repo</h2>

                    <div className="repository">
                        <p className="label-text">repository name</p>
                        <h6><span className="text-background">{workflowData.newRepository}</span></h6>
                    </div>
                    <div className="text-center">
                        <img src={arrowDown} alt="arrow down"/>
                        <h5 className="mb-4 mt-2">{workflowData.trainee.name}</h5>
                        <Button className="btn-transfer" onClick={handleTransfer}>Transfer naar trainee </Button>
                    </div>
                </>
            )
        }

    }


    return (
        <div className="">
            {renderContent()}
        </div>
    )

}

export default TransferRepo;