import GridLoader from "react-spinners/GridLoader";

const Spinner = () => {

    return (
        <div className="spinner-container">
            <GridLoader 
                size={15}
                color="#4525F2"
                className="spinner"
                loading={true}
            />
        </div>
    )
}

export default Spinner;