import React from 'react';
import {Button, Row} from 'react-bootstrap'
import Header from './Header'

const Success = (props) => {

    const handleReset = () => {
        props.handler({
            oldRepository: '',
            newRepository: '',
            trainee: {name: '', avatar: ''},
            dateWeek: '',
            issues: [],
            account: {username: '', userToken: ''}})
        window.location.reload(false)
    }

    const renderContent = () => {
        return (
            <Row>
                <p>De trainee is nu de eigenaar van de repository</p>
                <div className="success-container">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={props.data.trainee.avatar} className="card-img" alt="user-avatar"/>
                        </div>
                        <div className="col-md-9 d-flex align-items-center card-username">
                            <h2>{props.data.trainee.name}</h2></div>
                    </div>
                    <div className="row repo-info">
                        <div className="col-md-9">
                            <p>repository</p>
                            <h5>{props.data.newRepository}</h5>
                        </div>
                    </div>

                </div>
                <Button role="button" className="btn btn-primary" onClick={handleReset}>Nog een repository toewijzen</Button>
            </Row>
        )
    }


    return (
        <> 
            <Header setShowUser={props.setShowUser} showUser={props.showUser}/>
            <div className="container-succes">
                <h2 className="borderBottom">Gelukt!</h2>
                {renderContent()}
            </div>
        </>
    )
}

export default Success;