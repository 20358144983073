import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap'

import config from '../config/config'
import {getUser, setUser} from '../API/githubRestAPI'

const PopupGegevens = (props) => {

    const [username, setUsername] = useState("")
    const [userToken, setUserToken] = useState("")

    useEffect(() => {
        setUsername(getUser().username)
        setUserToken(getUser().userToken)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setUser(username, userToken);
        props.handler({account: {username, userToken}});
        props.setShowUser(false)
    }

    const renderContent = () => {
        return (
            <>
                <h4>Vul jouw Github gegevens in</h4>
                <Form className="row" onSubmit={handleSubmit}>
                    <Form.Group className="col-3">
                        <Form.Label>Gebruikersnaam</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Jouw Github gebruikersnaam"
                            name="username"
                            id="username"
                            value={username || ""}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="col-7">
                        <Form.Label>Personal AccesToken</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="bijv. 'ghp_IqIMNOZH6zOWIEB4T9A2g4EHMy8J14204HA5'"
                            name="userToken"
                            id="userToken"
                            value={userToken || ""}
                            onChange={(e) => setUserToken(e.target.value)}
                        />
                        <Form.Text className="text-url">
                            Lees <a href={config.githubTokenHelp}>hier</a> meer over hoe je
                            deze opvraagt
                        </Form.Text>
                    </Form.Group>
                    <div className="col-2 col-btn"><Button className="btn-block btn-dark" type="submit">Opslaan</Button>
                    </div>
                </Form>
            </>
        )
    }

    return (
        <div className="">
            {renderContent()}
        </div>
    )

}

export default PopupGegevens;