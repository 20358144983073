// BASE_URL = "https://api.github.com"
// GET_REPOSITORIES = "GET /orgs/Educom-Opleidingen/repos"
// POST_REPOSITORY_TEMPLATE = "POST /repos/Educom-Opleidingen/{template_repo}/generate"
//
// PERS_ACCESS_TOKEN = "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"


const config = {
    app: {
        title: "TRAINEE REPOSITORIES",
        accountButton: "Accountgegevens"
    },
    debug: false,
    baseUrl: "https://api.github.com",
    getRepositories: "GET /orgs/Educom-Opleidingen/repos",
    //postRepositoryTemplate: "POST /repos/Educom-Opleidingen/{template_repo}/generate",

    cockpitToken: "d1b8b43a128ab454e07a7f9c153bc0",
    cockpitURL: "https://cockpit.educom.nu/api/collections/get/Issues",

    //tmpIssue: "https://onboarding.educom.nu/issues.json",

    githubTokenHelp: "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token",
    defaultAvatar: "https://thetreeparty.nl/wp-content/uploads/2018/05/avatar-placeholder.png",
    status: {
        oke: "Oke", err: "Error"
    },
    errors: {
        err200: {code: 200, status: "ok", action: "", msg: "goedhoor"},
        err403: {code: 200, status: "ok", action: "", msg: "goedhoor"},
        err404: {code: 200, status: "ok", action: "", msg: "goedhoor"},
        err422: {code: 200, status: "ok", action: "", msg: "goedhoor"},
        err500: {code: 200, status: "ok", action: "", msg: "goedhoor"}
    }
}


export default config