import React from "react";
import {Button} from "react-bootstrap";
import config from "../config/config";

const Header = (props) => {

    const handleReset = () => {
        props.handler({
            oldRepository: '',
            newRepository: '',
            trainee: {name: '', avatar: ''},
            dateWeek: '',
            issues: [],
            account: {username: '', userToken: ''}})
        window.location.reload(false)
    }

    return (
        <header className="App-header">

            <a onClick={handleReset} style={{cursor: 'pointer'}}><h1 className="title-nav">{config.app.title}</h1></a>
            <Button
                variant="primary"
                onClick={() => props.setShowUser(!props.showUser)}
                aria-controls="popup"
                aria-expanded={props.showUser}
            >{config.app.accountButton}</Button>
        </header>
    );
};

export default Header;
