import React, {useState} from "react"
import {Form, Row} from 'react-bootstrap'


const ChooseTemplate = (props) => {

    const [allTemplates] = useState(props.templates)
    const [filteredTemplates, setFilteredTemplates] = useState(props.templates)


    const onChangeValue = (e) => {

        let c = filteredTemplates[e.target.value]
        let repo = c.githubUrl.split("template-")[1]

        const uniq = Math.floor(Date.now() / 1000)

        props.handler({
            oldRepository: `template-${repo}`,
            newRepository: `educom-${repo}-${uniq}`,
            githubURL: c.githubUrl
        });

        props.active(true)
    }

    const filterTemplates = (e) => {
        let keyw = e.target.value.toLowerCase()
        let result = allTemplates.filter(item => item.subject.toLowerCase().includes(keyw)
            || item.owner.toLowerCase().includes(keyw));
        setFilteredTemplates(result)
    }

    const renderContent = () => {
        return (
            <>
                <h2 className="borderBottom">1. Kies een template</h2>

                <div className="filterTemplates mb-3 mx-0">
                    <Form as={Row} className="mx-0">
                        <Form.Control
                            type="search"
                            placeholder="Zoek template"
                            className="me-2"
                            aria-label="search"
                            onChange={(e) => filterTemplates(e)}
                        />
                    </Form>
                </div>

                <div className="mb-3 radio-wrapper" onChange={onChangeValue}>
                    {filteredTemplates.map((template, index) => {
                        return (
                            <label className="list-group-item" key={index}>
                                <Form.Check type="radio"
                                            label={`${template.subject} (${template.owner})`}
                                            value={index}
                                            name="template"/>
                                <p>{template.description}</p>
                            </label>
                        )
                    })}
                </div>
            </>
        )
    }


    return (
        <div className="mx-auto">
            {renderContent()}
        </div>
    )

}

export default ChooseTemplate;